<template>
   <div class="mt-6">
    <h2 class="text-2xl font-semibold  p-6 leading-8 create-acct">Create a new account</h2>
    <form @submit.prevent="registerAccount">
       <div class="flex justify-between gap-4">
            <div class="mb-4 px-4">
                <Input  v-model="user.firstName" label="First name" placeholder="Jon"
                    width="100%" required  errorText="First name is required"
                     type="text" :validation="rules.firstName"
                />  
            </div> 
            <div class="mb-4 px-4">
                <Input  v-model="user.lastName" label="Last name" placeholder="Surname"
                    width="100%" required  errorText="Last name is required"
                     type="text"  :validation="rules.lastName"
                /> 
            </div>
       </div>
       <div class="mb-4 px-4">
            <Input   label="Email address" v-model="user.email" placeholder="princegodzilla@gmail.com" width="100%" required 
            type="email" :validation="rules.email" 
            /> 
       </div>
       <div class="mb-4 px-4">
            <TelInput  id="phone" label="Phone number (optional)" v-model="user.phoneNumber" width="w-full"
                class="mt-4" placeholder="Enter Phone Number"   :validation="rules.phoneNumber" 
            />
       </div>

       <div class="mb-4 px-4">
            <Input  label="Business name" v-model="user.businessName" placeholder="Enter business name" width="100%" required 
                type="text" 
            /> 
       </div>
        <div class="continue-Btn w-full flex justify-end items-center  p-2 text-right h-20">
            <Button text="Continue" type="submit"  :loading="registerLoading"/>
        </div>
    </form>
   </div>
</template>

<script>
import { Input, TelInput } from '@/UI/Input';
import { Button } from '@/UI/Button';
import {CREATE_WIRE_ACCOUNT } from '@/utils/api/wire'
export default {
    components:{
        Input,
        Button,
        TelInput
    },
    data(){
        return{
            user:{
                firstName:'',
                lastName:'',
                email:'',
                phoneNumber:'',
                businessName:''
            },
            registerLoading: false
        }
    },
    computed:{
        rules() {
            return {
                firstName: this.user.firstName.length !== 0,
                lastName: this.user.lastName.length !== 0,
                email: this.user.email.length !== 0,
                phoneNumber: this.user.phoneNumber.length !== 0,
            };
        },
    },
    methods: {
        // back() {
        //     this.$emit('back');
        // },
        // next() {
        //     // this.$emit('next');
        // },
        registerAccount(){
            this.registerLoading = true;
            CREATE_WIRE_ACCOUNT(this.user)
                .then((response) => {
                    this.registerLoading = false;
                    this.$emit('setWireAcct', response.data.data)
                    this.$emit('next')
                }).catch(() => {
                    this.registerLoading = false
                })
        }
    }
}
</script>

<style scoped>
   
    .cancel{
        background: #EEF2F6;
        color: #697586;
    }
    input{
        background: #E3E8EF;
        color:#9AA4B2;
    }
    .opt{
        color: #697586;
    }
    .continue-Btn-area{
        min-height: 80px;
        border-top: 1px solid #E3E8EF;
        background: #FCFCFD;
    }
    .continue-Btn{
        background:#FCFCFD;
        border-top: 1px solid #E3E8EF;
    }
    .create-acct{
        color: #202939;
    }
    .star{
        color:#7261D0;
    }
</style>